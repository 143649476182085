import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"; // Import necessary methods

const firebaseConfig = {
  apiKey: "AIzaSyCaaKITQc9B8raEr6Zbf4GG_UZdbNfa22o",
  authDomain: "clicksend-messaging-app.firebaseapp.com",
  projectId: "clicksend-messaging-app",
  storageBucket: "clicksend-messaging-app.firebasestorage.app",
  messagingSenderId: "84596499368",
  appId: "1:84596499368:web:1d7c0837f1f171a791b39d"
};

const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app); // Initialize Firestore

