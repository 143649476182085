import axios from "axios";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  increment,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  Timestamp
} from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import "./App.css";
import { firestore } from "./firebase.js";

const notificationSound = new Audio(`${process.env.PUBLIC_URL}/notification-sound.mp3`);

function ChatComponent({ loggedInUser }) {
  const [contacts, setContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const [chatMessages, setChatMessages] = useState([]);
  const [showAddContactModal, setShowAddContactModal] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const [newContactNumber, setNewContactNumber] = useState("");
  const [error, setError] = useState(null); // State for error messages
  const chatMessagesEndRef = useRef(null);

  const clearError = () => setError(null); // Clear error banner

  // Format Firestore timestamp into a readable string
  const formatTimestamp = (timestamp) => {
    if (!timestamp) return "";
    const now = new Date();
    const messageDate = timestamp.toDate();

    const isToday = now.toDateString() === messageDate.toDateString();
    const isYesterday =
      new Date(now - 86400000).toDateString() === messageDate.toDateString();

    if (isToday) {
      return messageDate.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
    }

    if (isYesterday) {
      return `Yesterday, ${messageDate.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}`;
    }

    return `${messageDate.getDate()}/${messageDate.getMonth() + 1}, ${messageDate.toLocaleTimeString(
      [],
      { hour: "2-digit", minute: "2-digit" }
    )}`;
  };

  const addNewContact = async () => {
    if (!newContactNumber.trim()) return;
    const trimmedNumber = newContactNumber.trim();

    const existingContact = contacts.find((contact) => contact.phoneNumber === trimmedNumber);

    if (existingContact) {
      setSelectedContact(trimmedNumber);
      setShowAddContactModal(false);
      return;
    }

    try {
      const newContactRef = doc(firestore, "users", loggedInUser.username, "messages", trimmedNumber);
      await setDoc(newContactRef, { lastMessage: "", lastMessageSender: "", timestamp: null }, {merge: true});

      setSelectedContact(trimmedNumber);
      setShowAddContactModal(false);
    } catch (error) {
      setError("Failed to add new contact. Please try again.");
    }
  };

  useEffect(() => {
    if (!loggedInUser.phoneNumber) return;
  
    const protocol = window.location.protocol === "https:" ? "wss" : "ws";
    let ws;
    let reconnectTimeout;
    const HEARTBEAT_INTERVAL = 40000; // 40 seconds
    const RECONNECT_DELAY = 5000; // 5 seconds
    let heartbeatInterval;
    let reconnectAttempts = 0;
    const MAX_RECONNECT_ATTEMPTS = 10; // Maximum retry attempts
  
    const connectWebSocket = () => {
      ws = new WebSocket(
        `${protocol}://${window.location.host}?phone=${loggedInUser.phoneNumber}`
      );
  
      ws.onopen = () => {
        console.log("WebSocket connection established: " + loggedInUser.phoneNumber);
        reconnectAttempts = 0; // Reset reconnect attempts
  
        // Start sending heartbeat messages
        heartbeatInterval = setInterval(() => {
          if (ws.readyState === WebSocket.OPEN) {
            ws.send(JSON.stringify({ type: "heartbeat", message: "Keep alive" }));
          }
        }, HEARTBEAT_INTERVAL);
      };
  
      ws.onmessage = (event) => {
        try {
          console.log("Message received from WebSocket:", event.data);
          const message = JSON.parse(event.data);
  
          if (message.type === "inbound") {
            handleInboundMessage(message);
          }
        } catch (error) {
          console.error("Error parsing WebSocket message:", error);
        }
      };
  
      ws.onerror = (error) => {
        console.error("WebSocket error:", error);
        setError("WebSocket connection error.");
      };
  
      ws.onclose = (event) => {
        console.log(`WebSocket closed: ${event.code} - ${event.reason}`);
        clearInterval(heartbeatInterval); // Stop heartbeats
  
        if (!event.wasClean && reconnectAttempts < MAX_RECONNECT_ATTEMPTS) {
          console.log("Attempting to reconnect...");
          reconnectAttempts++;
          reconnectTimeout = setTimeout(connectWebSocket, RECONNECT_DELAY);
        } else if (reconnectAttempts >= MAX_RECONNECT_ATTEMPTS) {
          console.error("Max reconnect attempts reached. Giving up.");
        }
      };
    };
  
    connectWebSocket(); // Connect initially
  
    return () => {
      clearTimeout(reconnectTimeout); // Clear reconnection attempts
      clearInterval(heartbeatInterval); // Stop heartbeats
      if (ws) ws.close();
    };
  }, [loggedInUser.phoneNumber]);
  
    
  
  const handleInboundMessage = async (message) => {
    const { from, body, timestamp } = message;
    const parsedTimestamp = new Date(timestamp);

    const newMessage = {
      text: body.trim(),
      sender: "other",
      timestamp: parsedTimestamp,
      status: "received",
    };

    if (selectedContact !== from) {
      // Increment unread count in Firestore
      const contactMetadataRef = doc(
        firestore,
        "users",
        loggedInUser.username,
        "messages",
        from
      );
      await setDoc(
        contactMetadataRef,
        { unreadCount: increment(1) }, // Increment unread count
        { merge: true }
      );
      // Play notification sound
      notificationSound.play();
    } else {
      setChatMessages((prev) => [...prev, newMessage]);
    }
  
    const messagesRef = collection(
      firestore,
      "users",
      loggedInUser.username, // userId
      "messages",
      from, 
      "chats" // Sub-collection for storing chat messages
    );

    addDoc(messagesRef, {
      text: newMessage.text, // Use trimmed body
      sender: newMessage.sender,
      timestamp: Timestamp.fromDate(parsedTimestamp), // Firestore-compatible timestamp
      status: newMessage.status,
    }).catch((error) =>
      console.error("Error adding message to Firestore:", error)
    );
  
    // Step 5: Update the last message metadata in the contact's metadata
    const contactMetadataRef = doc(
      firestore,
      "users",
      loggedInUser.username, // userId
      "messages",
      from 
    );

    setDoc(
      contactMetadataRef,
      {
        lastMessage: newMessage.text,
        lastMessageSender: "other",
        timestamp: Timestamp.fromDate(parsedTimestamp),
      },
      { merge: true } // Merge to avoid overwriting other fields
    );

    // Update local state
    if (selectedContact === from) {
      setChatMessages((prev) => [...prev, newMessage]);
    }
  
    setContacts((prev) =>
      prev.map((contact) =>
        contact.phoneNumber === from
          ? { ...contact, lastMessage: newMessage.text, lastMessageSender: "other", timestamp: parsedTimestamp }
          : contact
      )
    );
  };
  
  useEffect(() => {
    const contactsRef = collection(firestore, "users", loggedInUser.username, "messages");

    const unsubscribe = onSnapshot(contactsRef, (snapshot) => {
      const data = snapshot.docs.map((doc) => ({
        phoneNumber: doc.id,
        lastMessage: doc.data().lastMessage || "",
        lastMessageSender: doc.data().lastMessageSender || "",
        timestamp: doc.data().timestamp || null,
        unreadCount: doc.data().unreadCount || 0, // Fetch unread count
      }));
      const sortedContacts = data.sort((a, b) => {
        if (!a.timestamp && !b.timestamp) return 0; // Both null
        if (!a.timestamp) return 1; // `a` goes below
        if (!b.timestamp) return -1; // `b` goes below
        return b.timestamp.seconds - a.timestamp.seconds; // Newest first
      });

      setContacts(sortedContacts);
    },
    (error) => {
      console.error("Error fetching contacts snapshot: ", error);
      setError("Failed to fetch contacts. Please try again.");
    }
  );

  return unsubscribe;
}, [loggedInUser]);

  useEffect(() => {
    if (!selectedContact) return;

    const messagesRef = collection(
      firestore,
      "users",
      loggedInUser.username,
      "messages",
      selectedContact,
      "chats"
    );

    const unsubscribe = onSnapshot(query(messagesRef, orderBy("timestamp", "asc")), (snapshot) => {
      setChatMessages(snapshot.docs.map((doc) => doc.data()));
    });

    return unsubscribe;
  }, [selectedContact]);

  useEffect(() => {
    if (chatMessagesEndRef.current) {
      chatMessagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chatMessages]);

  const sendMessage = async () => {
    if (!newMessage.trim()) {
      console.log("Message is empty, not sending."); // Log for empty message check
      return; // Don't send empty messages
    }
  
    // Step 1: Prepare the message object
    const message = {
      text: newMessage.trim(),
      sender: "user",
      timestamp: Timestamp.now(),
      status: "pending", // Initially set as "pending"
    };

    // Step 2: Add the message to local state with "pending" status
    setChatMessages((prev) => {
      const updatedMessages = [...prev, message];
      return updatedMessages;
    });
    setNewMessage(""); // Clear the input field
  
    try {
      // Step 3: Send the message via the API (external request to send SMS)
      console.log("Sending message to API:", {
        from: loggedInUser.phoneNumber,
        to: selectedContact,
        message: newMessage.trim(),
      });
  
      const response = await axios.post("/send-message", {
        from: loggedInUser.phoneNumber,
        to: selectedContact,
        message: newMessage.trim(),
      });
  
      // Step 4: On successful API call, update Firestore with "sent" status
      const messagesRef = collection(
        firestore,
        "users",
        loggedInUser.username, // userId
        "messages",
        selectedContact, // contactId
        "chats" // Sub-collection for storing chat messages
      );
  
      await addDoc(messagesRef, {
        text: newMessage.trim(),
        sender: "user",
        timestamp: Timestamp.now(),
        status: "sent", // Mark message as "sent" once it's delivered successfully
      });
    
      // Step 5: Update the last message metadata in the contact's metadata
      const contactMetadataRef = doc(
        firestore,
        "users",
        loggedInUser.username, // userId
        "messages",
        selectedContact // contactId
      );
  
      await setDoc(
        contactMetadataRef,
        {
          lastMessage: newMessage.trim(),
          lastMessageSender: "user",
          timestamp: Timestamp.now(),
        },
        { merge: true } // Merge to avoid overwriting other fields
      );
  
      // Step 6: Update the local state with the "sent" status for the message
      setChatMessages((prev) =>
        prev.map((msg, index) =>
          index === prev.length - 1 ? { ...msg, status: "sent" } : msg
        )
      );
  
    } catch (error) {
      // Step 7: If error occurs, update the message status to "failed"  
      setError("Failed to send message. Please try again.");
  
      // Mark message as "failed" in Firestore if there's an issue
      const messagesRef = collection(
        firestore,
        "users",
        loggedInUser.username, // userId
        "messages",
        selectedContact, // contactId
        "chats" // Sub-collection for storing chat messages
      );
  
      await addDoc(messagesRef, {
        text: newMessage.trim(),
        sender: "user",
        timestamp: Timestamp.now(),
        status: "failed", // Set status as "failed"
      });
    }
  };

  const handleContactClick = async (phoneNumber) => {
    setSelectedContact(phoneNumber);
    const contactMetadataRef = doc(
      firestore,
      "users",
      loggedInUser.username,
      "messages",
      phoneNumber
    );
    await setDoc(
      contactMetadataRef,
      { unreadCount: 0 }, // Reset unread count
      { merge: true }
    );
  };


  // Handle delete contact
  const handleDeleteContact = async (phoneNumber) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this chat?");
    if (!confirmDelete) return;

    try {
      // Delete the contact's chat history from Firestore
      const contactRef = doc(firestore, "users", loggedInUser.username, "messages", phoneNumber);
      await deleteDoc(contactRef);

      // Also delete the messages related to this contact
      const messagesRef = collection(firestore, "users", loggedInUser.username, "messages", phoneNumber, "chats");
      const messageSnapshot = await getDocs(messagesRef);
      messageSnapshot.forEach(async (messageDoc) => {
        await deleteDoc(messageDoc.ref);
      });

      // Update the UI by removing the contact
      setContacts((prev) => prev.filter((contact) => contact.phoneNumber !== phoneNumber));
      if (selectedContact === phoneNumber) {
        setSelectedContact(null); // Clear selected contact if the deleted contact was active
      }

    } catch (error) {
      setError("Failed to delete contact. Please try again.");
    }
  };

  return (
    <div className="chat-container">
      {error && (
        <div className="error-banner">
          <span>{error}</span>
          <button onClick={clearError}>✖</button>
        </div>
      )}
      <div className="contacts-panel">
        <div className="search-bar-container">
          <input type="text" className="search-bar" placeholder="Search contacts" />
          <button className="add-chat-btn" onClick={() => setShowAddContactModal(true)}>
            +
          </button>
        </div>
        {contacts.map((contact) => (
          <div
            key={contact.phoneNumber}
            className={`contact ${selectedContact === contact.phoneNumber ? "active" : ""} ${
              contact.unreadCount > 0 ? "unread-message" : ""
            }`}
            onClick={() => handleContactClick(contact.phoneNumber)}
          >
            <div className="contact-info">
              <div className="contact-header">
                <span className="phone-number">{contact.phoneNumber}</span>
                {contact.unreadCount > 0 && (
                  <div className="unread-indicator">{contact.unreadCount}</div>
                )}
                <button
                  className="delete-contact-btn"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent contact click event
                    handleDeleteContact(contact.phoneNumber);
                  }}
                >
                  ⛔
                </button>
              </div>
              <div className="last-message">
                {contact.lastMessageSender === "user" ? (
                  <>You: {contact.lastMessage}</>
                ) : (
                  contact.lastMessage
                )}
              </div>
              {contact.timestamp && (
                <div className="last-message-timestamp">
                  {formatTimestamp(contact.timestamp)}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
  
      <div className="chat-panel">
        {selectedContact ? (
          <>
            <div className="chat-header">{selectedContact}</div>
            <div className="chat-messages">
              {chatMessages.length > 0 ? (
                chatMessages.map((msg, index) => (
                  <div key={index} className={`message ${msg.sender}`}>
                    <div className="message-text">{msg.text}</div>
                    <div className="message-info">
                      <div className="message-timestamp">{formatTimestamp(msg.timestamp)}</div>
                        {msg.sender === "user" && (
                          <div className="message-status">
                            {msg.status === "sent" && (
                              <span className="status-icon sent">✔️</span>
                            )}
                            {msg.status === "failed" && (
                              <span className="status-icon failed">❌</span>
                            )}
                            {msg.status === "pending" && (
                              <span className="status-icon pending">...</span>
                            )}
                          </div>
                        )}
                    </div>
                  </div>
                ))
              ) : (
                <div className="no-messages">No messages yet...</div>
              )}
              <div ref={chatMessagesEndRef} />
            </div>
            <div className="message-input">
              <input
                type="text"
                className="message-input-field"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder="Type a message"
              />
              <button onClick={sendMessage}>Send</button>
            </div>
          </>
        ) : (
          <div className="white-text">Select a contact to start chatting...</div>
        )}
      </div>
  
      {showAddContactModal && (
        <div className="new-contact-panel">
          <div className="new-contact-form">
            <h2>Add New Contact</h2>
            <input
              className="new-contact-input"
              type="text"
              value={newContactNumber}
              onChange={(e) => setNewContactNumber(e.target.value)}
              placeholder="Enter contact number"
            />
            <button className="start-chat-btn" onClick={addNewContact}>
              Add Contact
            </button>
            <button className="cancel-new-contact" onClick={() => setShowAddContactModal(false)}>
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );  
}  

export default ChatComponent;