import React, { useEffect, useState } from "react";
import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import ChatComponent from "./ChatComponent.js";
import LoginComponent from "./LoginComponent.js";

function App() {
  const [loggedInUser, setLoggedInUser] = useState(() => {
    const user = JSON.parse(localStorage.getItem("loggedInUser"));
    return user ? user : null;
  });

  // Clear user session on page refresh
  useEffect(() => {
    // Listen for page reload or refresh
    const handleBeforeUnload = () => {
      localStorage.removeItem("loggedInUser");
      localStorage.removeItem("lastActivity");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const handleActivity = () => {
      localStorage.setItem("lastActivity", Date.now());
    };

    const checkInactivity = () => {
      const lastActivity = parseInt(localStorage.getItem("lastActivity"));
      if (Date.now() - lastActivity > 15 * 60 * 1000) {
        localStorage.removeItem("loggedInUser");
        localStorage.removeItem("lastActivity");
        setLoggedInUser(null);
        alert("You have been logged out due to inactivity.");
      }
    };

    window.addEventListener("click", handleActivity);
    window.addEventListener("keypress", handleActivity);

    const inactivityTimer = setInterval(checkInactivity, 60000);

    if (loggedInUser) {
      localStorage.setItem("lastActivity", Date.now());
    }

    return () => {
      clearInterval(inactivityTimer);
      window.removeEventListener("click", handleActivity);
      window.removeEventListener("keypress", handleActivity);
    };
  }, [loggedInUser]);

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={loggedInUser ? <Navigate to="/chat" /> : <LoginComponent setLoggedInUser={setLoggedInUser} />}
        />
        <Route
          path="/chat"
          element={loggedInUser ? <ChatComponent loggedInUser={loggedInUser} /> : <Navigate to="/" />}
        />
      </Routes>
    </Router>
  );
}

export default App;
