// LoginComponent.js
import { doc, getDoc } from "firebase/firestore"; // Modular Firestore functions
import React, { useState } from "react";
import { firestore } from "./firebase.js"; // Firestore instance
import "./LoginComponent.css";

function LoginComponent({ setLoggedInUser }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Loading state

  // Validate username and password input
  const validateInputs = () => {
    if (username.trim() === "" || password.trim() === "") {
      setError("Username and password are required.");
      return false;
    }
    if (password.length < 6) {
      setError("Password must be at least 6 characters long.");
      return false;
    }
    return true;
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");
    if (!validateInputs()) {
      return; // Stop login if validation fails
    }
    setIsLoading(true); // Show loading state during login

    try {
      // Fetch user data from Firestore
      const userDocRef = doc(firestore, "users", username); // Document path in Firestore
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        if (userData.password === password) {
          // Successful login
          setLoggedInUser({
            username,
            phoneNumber: userData.phoneNumber,
          });
          localStorage.setItem(
            "loggedInUser",
            JSON.stringify({
              username,
              phoneNumber: userData.phoneNumber,
            })
          );
        } else {
          // Invalid password
          setError("Invalid password. Please try again.");
        }
      } else {
        // User not found
        setError("User does not exist. Please check your username.");
      }
    } catch (err) {
      console.error("Error during login:", err);
      setError("Something went wrong. Please try again later.");
    } finally {
      setIsLoading(false); // Clear loading state
    }
  };

  return (
    <div className="login-container">
      <h2>Login</h2>
      <form onSubmit={handleLogin}>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit" disabled={isLoading}>
          {isLoading ? "Logging in..." : "Login"}
        </button>
      </form>
      {error && <p className="error">{error}</p>}
    </div>
  );
}

export default LoginComponent;
